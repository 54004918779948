<template>
  <div class="mainBox">
      <div class="wrap">
          <div class="isLogo"><img src="../assets/img/logo.png" alt=""><span class="zi"></span>
          </div>
          <div class="rightBox">
            <div class="isNav"  v-for="(item,index) in isList" :key="index" >
            <!-- <div class="isNav"  v-for="(item,index) in isList" :key="index" :class="$route.name == item.name? 'active': ''" > -->
              <div>{{ item.title }}</div>
              <!-- <div class="isUnderLine" :style="{'opacity':$route.name == item.name || $route.name == item.rediret? 1 : 0}"></div> -->
            </div>
            <div class="downloadBtn" @click="downloadFn" style="cursor: pointer;">立即下载</div>
          </div>
      </div>
  </div>
</template>
<script>
  export default {
    name: 'AllHead',
    data(){
      return {
        isList: [
          {title: 'Directx类dII修复'},
          {title: 'VC类dll修复'},
          {title: 'Steam类dIl修复'},
        ],
        ishost:'',
      }
    },
    mounted(){
      this.ishost = window.location.host
    },
    methods:{
      toPage(v){
        this.$router.push(this.isList[v].path)
      },

      goRoute(v){
        let aaa = ['/','isAbout','lxUs']
        this.$router.push(aaa[v])
      }
    }
  }
</script>
<style lang="scss" scoped>
  .mainBox {
    width: 100%;
    height: 70px;
    // background-color: rgba(255,255,255,0.7);
    .wrap {
      width: 1180px;
      height: 100%;
      margin: 0 auto;
      position: relative;
      display: flex;
      justify-content: space-between;
      .isLogo {
        width: 600px;
        height: 100%;
        display: flex;
        justify-content: left;
        align-items: center;

        .isRouter {
          width: 200px;
          font-size: 14px;
          display: flex;
          justify-content: space-around;
          line-height: 28px;
          color: #5B5D61;
          font-weight: 600;

          .ishover {
            cursor: pointer;
          }
          // .ishover:hover {

          // }
        }

        img {
          width: 179px;
          height: 36px;
          object-fit: contain;
        }

        .zi {
          width: 160px;
          height: 28px;
          font-size: 14px;
          line-height: 28px;
          display: inline-block;
          margin-left: 10px;
          color: #5B5D61;
          font-weight: 600;
        }
      }
  
      .rightBox {
        width: 550px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .downloadBtn {
          width: 91px;
          height: 32px;
          background: #538BFF;
          border-radius: 4px;
          text-align: center;
          line-height: 32px;
          color: #fff;
        }

        .isNav {

          // width: 114px;
          height: 19px;
          font-size: 14px;
          font-weight: 600;
          color: #14142E;
          line-height: 16px;
          // cursor: pointer;
          // font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
          .isUnderLine {
            height: 3px;
            margin-top: 3px;
            background-color: var(--base_color);
          }

        }

        .active {
          color: var(--base_color);
        }
      }
    }

  }
</style>